<template>
  <div>
    <div class="container">
      <div class="sub-head">Get Started With SharKaro</div>
      <div class="sub-des">
        ShareKaro is a popular file sharing app, fast and no data usage. You can
        share all kinds of files to Android/iOS/PC users, including videos,
        apps, photos, music, etc.
      </div>
      <div class="item">
        <div
          class="item-content"
          v-for="(item, index) in itemList"
          :key="index"
          :type="item.type"
          @click="handleDownload(item.type)"
        >
          <div :class="['icon', 'icon-' + item.icon]"></div>
          <div class="right">
            <div class="item-des">{{ item.des }}</div>
            <div class="item-title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <span>Contact Us</span>
      <a href="mailto:sadhilanandstudio@gmail.com">sadhilanandstudio@gmail.com</a>
      <span class="copyright">Copyright©RE PEAK (HK) CO., LIMITED</span>
    </div>
  </div>
</template>

<script>
import { getAllUrlParams, getGup } from "@/assets/js/utils.js";
export default {
  name: "HomeFooter",
  data() {
    return {
      itemList: [
        { icon: "pc-windows", title: "For Window", des: "", type: "windows" },
        { icon: "pc-apk", title: "Download apk", des: "", type: "apk" },
        {
          icon: "pc-google",
          title: "Google Play",
          des: "GET IT ON",
          type: "googlePlay",
        },
        {
          icon: "pc-ios",
          title: "App Store",
          des: "Download on the",
          type: "appStore",
        },
      ],
      downloadMap: {
        a: {
          apk: "",
          googlePlay:
            "https://play.google.com/store/apps/details?id=com.sharekaro.app",
          appStore:
            "https://apps.apple.com/us/app/share-karo-share-file-transfer/id1584597372",
          windows: "https://www.sharekaro.app/d/websiteA/sharekaro-setup.exe",
          //   Apk_PC: "https://www.sharekaro.app/d/website/ZShare_pc.apk"
        },
        b: {
          apk: "https://www.sharekaro.app/d/websiteB/ZShare.apk",
          googlePlay:
            "https://play.google.com/store/apps/details?id=com.sharekaro.app",
          appStore:
            "https://apps.apple.com/us/app/share-karo-share-file-transfer/id1584597372",
          windows: "https://www.sharekaro.app/d/websiteB/sharekaro-setup.exe",
          //   Apk_PC: "https://www.sharekaro.app/d/website/ZShare_pc.apk"
        },
        c: {
          apk: "https://www.sharekaro.app/d/websiteC/ZShare.apk",
          googlePlay:
            "https://play.google.com/store/apps/details?id=com.sharekaro.app",
          appStore:
            "https://apps.apple.com/us/app/share-karo-share-file-transfer/id1584597372",
          windows: "https://www.sharekaro.app/d/websiteC/sharekaro-setup.exe",
          //   Apk_PC: "https://www.sharekaro.app/d/website/ZShare_pc.apk"
        },
        d: {
          apk: "https://www.sharekaro.app/d/website/ZShare.apk", // 没用到
          googlePlay:
            "https://play.google.com/store/apps/details?id=com.sharekaro.app",
          appStore:
            "https://apps.apple.com/us/app/share-karo-share-file-transfer/id1584597372",
          windows: "https://www.sharekaro.app/d/website/sharekaro-setup.exe", // 没用到
          //   Apk_PC: "https://www.sharekaro.app/d/website/ZShare_pc.apk"
        },
      },
      downloadUrl: {},
      usoAndroidMap: {
        website: "https://www.sharekaro.app/d/apk/website/sharekaro.apk", // 官网默认链接
        webshare: "https://www.sharekaro.app/d/apk/webshare/sharekaro.apk", // webshare带来的下载
        kol_transfer:
          "https://www.sharekaro.app/d/apk/kol_transfer/sharekaro.apk", // KOL通过介绍传输带来的量
        kol_download:
          "https://www.sharekaro.app/d/apk/kol_download/sharekaro.apk", // KOL通过介绍下载带来的量
        website_3rd:
          "https://www.sharekaro.app/d/apk/website_3rd/sharekaro.apk", // 三方商店带来的量
        ga_search: "https://www.sharekaro.app/d/apk/ga_search/sharekaro.apk", // GA投放带来的量
      },
      usoWindowsMap: {
        website: "https://www.sharekaro.app/d/exe/website/sharekaro-setup.exe",
        webshare:
          "https://www.sharekaro.app/d/exe/webshare/sharekaro-setup.exe",
        kol_transfer:
          "https://www.sharekaro.app/d/exe/kol_transfer/sharekaro-setup.exe",
        kol_download:
          "https://www.sharekaro.app/d/exe/kol_download/sharekaro-setup.exe",
        website_3rd:
          "https://www.sharekaro.app/d/exe/website_3rd/sharekaro-setup.exe",
        ga_search:
          "https://www.sharekaro.app/d/exe/ga_search/sharekaro-setup.exe",
      },
      downlink: "",
      uso: "",
      gup: "d",
      testId: "",
    };
  },
  mounted() {
    this.downloadUrl = this.downloadMap["d"];
    this.uso =
      getAllUrlParams("uso") === "" ? "website" : getAllUrlParams("uso");
    this.process(this.uso);
    this.gup = getGup();
    this.$nextTick(() => {
      let _uso = this.uso;
      class IOCreateor {
        constructor(callback) {
          if (!callback || typeof callback !== "function") {
            throw new Error("need callback[function] param");
          }
          this.io = new IntersectionObserver((entries) => {
            entries.forEach((item) => {
              //判断目标元素是否出现在可视区
              if (item.intersectionRatio > 0) {
                if (item.target) {
                  callback(item.target, item);
                  this.io.unobserve(item.target); //停止监听目标元素
                }
              }
            });
          });
        }
        observe(el) {
          if (!this.io || !el) {
            return;
          }
          this.io.observe(el);
        }

        disconnect() {
          if (!this.io) {
            return;
          }
          this.io.disconnect();
        }
      }
      const myIntersectionObserver = new IOCreateor((node) => {
        let type = node.getAttribute("type");
        let pveCur = `/fe-skWebsite/home/download/${type}`;
        let event = `show_ve_${type}`;
        this.$beyla.report({
          pveCur: pveCur,
          eventName: "show_ve",
          extras: "online_1_0110",
          ab: this.gup,
          type,
        });
        window.gtag("event", event, {
          event_category: "show_ve",
          event_label: pveCur,
          _uso,
        });
      });
      const btnList = Array.prototype.slice.call(
        document.querySelector(".item").children
      );
      btnList.forEach((element) => {
        myIntersectionObserver.observe(element);
      });
    });
  },
  methods: {
    process(uso) {
      let allParamsString = "";
      let baseParams = {
        utm_source: uso,
        utm_medium: uso,
        utm_campaign: uso,
      };
      Object.keys(baseParams).forEach(function (key) {
        allParamsString += `&${key}=${baseParams[key]}`;
      });
      this.downloadUrl.googlePlay =
        this.downloadUrl.googlePlay +
        "&referrer=" +
        encodeURIComponent(allParamsString);
      this.downloadUrl.appStore =
        this.downloadUrl.appStore + "?" + allParamsString;
    },
    handleDownload(type) {
      let _uso = this.uso;
      if (type === "apk") {
        this.downlink =
          this.usoAndroidMap[_uso] || this.usoAndroidMap["website"];
        this.$beyla.report({
          pveCur: "/fe-skWebsite/home/download/apk",
          eventName: "click_ve",
          extras: "online_1_0110",
          ab: this.gup,
          type,
        });
        window.gtag("event", "click_ve_apk", {
          event_category: "click_ve",
          event_label: "/fe-skWebsite/home/download/apk",
          _uso,
        });
        window.location = this.downlink;
      } else if (type === "windows") {
        this.downlink =
          this.usoWindowsMap[_uso] || this.usoWindowsMap["website"];
        this.$beyla.report({
          pveCur: "/fe-skWebsite/home/download/windows",
          eventName: "click_ve",
          extras: "online_1_0110",
          ab: this.gup,
          type,
        });
        window.gtag("event", "click_ve_apk", {
          event_category: "click_ve",
          event_label: "/fe-skWebsite/home/download/windows",
          _uso,
        });
        window.location = this.downlink;
      } else if (type === "googlePlay") {
        this.downlink = this.downloadUrl[type];
        this.$beyla.report({
          pveCur: "/fe-skWebsite/home/download/googlePlay",
          eventName: "click_ve",
          extras: "online_1_0110",
          ab: this.gup,
          type,
        });
        window.gtag("event", "click_ve_apk", {
          event_category: "click_ve",
          event_label: "/fe-skWebsite/home/download/googlePlay",
          _uso,
        });
        window.location = this.downlink;
      } else if (type === "appStore") {
        this.downlink = this.downloadUrl[type];
        this.$beyla.report({
          pveCur: "/fe-skWebsite/home/download/appStore",
          eventName: "click_ve",
          extras: "online_1_0110",
          ab: this.gup,
          type,
        });
        window.gtag("event", "click_ve_apk", {
          event_category: "click_ve",
          event_label: "/fe-skWebsite/home/download/appStore",
          _uso,
        });
        window.location = this.downlink;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";
.container {
  //width: vw(1600);
  height: vw(480);
  background: linear-gradient(270deg, #30c7ff 0%, #2f9cf6 100%);
  text-align: center;
  padding: vw(105) vw(240);
  .sub-head {
    //width: vw(934);
    font-family: HelveticaNeue-Medium, HelveticaNeue;
    font-weight: 500;
    color: #ffffff;
    line-height: vw(61);
    font-size: vw(50);
  }
  .sub-des {
    //width: vw(934);
    font-size: vw(24);
    font-family: HelveticaNeue;
    color: #ffffff;
    line-height: vw(29);
    margin: vw(20) vw(93) vw(68);
    padding: 0 vw(10);
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      width: vw(256);
      height: vw(60);
      background: #333131;
      border-radius: vw(4);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .right {
        padding: vw(7) vw(15);
        .item-des {
          font-size: vw(12);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: vw(17);
          text-align: left;
        }
        .item-title {
          font-size: vw(24);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: vw(33);
        }
      }
    }
  }
}
.footer {
    .copyright{
        padding-left: maxPx(20);
    }
  //width: vw(1600);
  height: vw(61);
  text-align: center;
  line-height: vw(61);
  background: #f4f9ff;
  font-size: vw(14);
  font-family: HelveticaNeue;
  span {
    color: #666666;
  }
  a {
    margin-left: vw(24);
    color: #333333;
    text-decoration: underline;
  }
}
@media screen and (max-width: 1080px) {
  .container {
    width: minPx(1600);
    height: minPx(480);
    padding: minPx(105) minPx(240);
    .sub-head {
      width: minPx(934);
      line-height: minPx(61);
      font-size: minPx(50);
    }
    .sub-des {
      width: minPx(934);
      font-size: minPx(24);
      line-height: minPx(29);
      margin: minPx(20) minPx(93) minPx(68);
      padding: 0 minPx(10);
    }
    .item {
      .item-content {
        width: minPx(256);
        height: minPx(60);
        border-radius: minPx(4);
        .right {
          padding: minPx(7) minPx(15);
          .item-des {
            font-size: minPx(12);
            text-align: left;
          }
          .item-title {
            font-size: minPx(24);
            line-height: minPx(33);
          }
        }
      }
    }
  }
  .footer {
    .copyright{
        padding-left: maxPx(20);
    }
    width: minPx(1600);
    height: minPx(61);
    line-height: minPx(61);
    font-size: minPx(14);
    a {
      margin-left: minPx(24);
    }
  }
}
@media screen and (min-width: 1601px) {
  .container {
    height: maxPx(480);
    padding: maxPx(105) maxPx(240);
    .sub-head {
      line-height: maxPx(61);
      font-size: maxPx(50);
    }
    .sub-des {
      font-size: maxPx(24);
      line-height: maxPx(29);
      margin: maxPx(20) maxPx(93) maxPx(68);
      padding: 0 maxPx(10);
    }
    .item {
      width: maxPx(1120);
      margin: 0 auto;
      .item-content {
        width: maxPx(256);
        height: maxPx(60);
        border-radius: maxPx(4);
        .right {
          padding: maxPx(7) maxPx(15);
          .item-des {
            font-size: maxPx(12);
            text-align: left;
          }
          .item-title {
            font-size: maxPx(24);
            line-height: maxPx(33);
          }
        }
      }
    }
  }
  .footer {
    .copyright{
        padding-left: maxPx(20);
    }
    height: maxPx(61);
    line-height: maxPx(61);
    font-size: maxPx(14);
    a {
      margin-left: maxPx(24);
    }
  }
}
</style>
